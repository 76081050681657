<template>
  <div class="news-list" v-if="!isProcessing">
    <detail v-for="article in articles" :key="article.aid" :article="article" />
  </div>
</template>

<script>
import detail from '@/components/news/detail.vue'

export default {
  name: 'news-list',
  components: { detail },
  mounted () {
    if (this.articles.length === 0) this.$store.dispatch('articles/getArticles')

    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object[]} ニュース情報一覧
     */
    articles () {
      return this.$store.getters['articles/articles']
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.news-list {
  padding: $padding_height $padding_width;
  margin-top: $header_height;
}
</style>
