<template>
  <div class="article-detail">
    <!-- <deleting ref="deleting" :msg="dialogMsg" @action="deleteArticle()" /> -->
    <div class="article-detail__article">
      <p class="article-detail__article__title">{{ article.titles.jp }}</p>
    </div>
    <div class="article-detail__btn">
      <v-btn class="article-detail__btn__edit" depressed outlined
             @click="$router.push({ name: 'news_edit', params: { aid: article.aid }})">
        編集する
      </v-btn>
      <!-- <v-btn class="article-detail__btns__btn--delete" depressed outlined @click="$router.push({ name: 'article_delete', params:{aid: article.aid} })">削除する</v-btn> -->
    </div>
  </div>
</template>

<script>
// import Deleting from '@/components/common/dialog.vue'

export default {
  // components: { Deleting },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    //   dialogMsg: {
    //     title: '「' + this.article.name + '」の削除',
    //     text: '一度削除すると、復元することはできません。\n本当に削除してもよろしいですか？',
    //     action: '削除'
    //   }
    }
  },
  methods: {
    // /**
    //  * アセットのデリート
    //  */
    // async deleteArticle () {
    //   this.$store.commit('setProcessing', true)

    //   await this.$store.dispatch('articles/updateArticle', { aid: this.article.aid, params: { isDeleted: true, updatedAt: new Date() } })

    //   this.$store.commit('setProcessing', false)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.article-detail {
  padding: $padding_height $padding_width;
  border-bottom: 1px solid $gray_color;
  &__article {
    text-align: left;
    &__title {
      margin: 0;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
  &__btn {
    margin: 16px 0 0 0;
    text-align: center;
    &__edit {
      width: 100px;
      margin: 0 15px;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $white_color;
      border-radius: 15px;
      &.v-btn:not(.v-btn--round) {
        height: 40px;
      }
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: $orange_color;
      }
      // &--delete {
      //   @extend .article-detail__btn__edit;
      //   color: $green_color;
      // }
    }
  }
}

</style>
